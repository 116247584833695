<template>
<v-card :dark='dark' tile >
  <v-card-title class='subtitle-1 overline' >
    Filters
    <v-spacer/>
    <v-btn small outlined rounded
      class='mr-2' :loading='loading'
      @click='apply' >
      Apply
    </v-btn>
    <v-btn @click='clear' small outlined rounded >
      clear <v-icon small >mdi-filter-off</v-icon></v-btn>
  </v-card-title>
  <v-divider/>
  <v-card-text>
    <v-subheader class='overline py-0 pl-0' >
      Date range
      <v-spacer/>
      <v-btn small v-if='pickerType==="date"'
        @click="dateRange=[];pickerType='month'" >Month</v-btn>
      <v-btn v-else small
        @click="dateRange=[];pickerType='date'" >Date</v-btn>
    </v-subheader>

    <v-card tile >
      <v-window v-model="pickerType" vertical >
        <v-window-item value='month' >
          <v-date-picker no-title full-width range tile
            type='month'
            v-model='dateRange' />
        </v-window-item >
        <v-window-item value='date' >
          <v-date-picker no-title full-width range tile
            v-model='dateRange' />
        </v-window-item >
      </v-window>

      <v-footer dark >{{dateRangeString}}
      </v-footer>
    </v-card>

    <v-subheader class='overline py-0 pl-0' >Space</v-subheader>
    <!-- {{facetSpaces}} -->
    <v-checkbox dense small hide-details class='my-0'
      v-for='space in facetSpaces'
      v-model='selectedSpaces'
      :key='space.id'
      :label='`${space.title} (${space.count})`'
      :value='space.id' />

    <v-subheader class='overline py-0 pl-0' >Practitioner</v-subheader>
    <v-checkbox dense small hide-details class='my-0'
      v-for='p in facetPractitioners'
      v-model='selectedPractitioners'
      :key='p.id'
      :label='`${p.full_name} (${p.count})`'
      :value='p.id' />

    <v-subheader class='overline py-0 pl-0' >Status</v-subheader>
    <v-checkbox dense small hide-details class='my-0'
      v-for='(count, status) in facetStatuses'
      v-model='selectedStatuses'
      :key='status'
      :label='`${status} (${count})`'
      :value='status' />
    <!-- {{facets.status}} -->
  </v-card-text>
  <v-card-actions >
    <v-btn block @click='apply' :loading='loading' >Apply Filters</v-btn>
  </v-card-actions>
  <!-- <pre class='caption' v-text='params' /> -->
</v-card>
</template>
<script>
import {mapGetters} from 'vuex'
import {DateTime} from 'luxon'

export default {
  name: 'InvoiceFilter',
  props: {
    dark: { type: Boolean, default: true },
    facets : { type: Object, required: false },
    facetString: { type: String, default: '' },
    loading: { type: Boolean, default: false }
  },
  data () {
    return {
      dateRange: [],
      selectedSpaces: [],
      selectedStatuses: [],
      selectedPractitioners: [],
      pickerType: 'month',
      pageSize: 20
    }
  },
  methods: {
    clear () {
      this.dateRange = []
      this.selectedSpaces = []
      this.selectedPractitioners = []
      this.selectedStatuses = []
      this.apply()
    },
    apply () {
      this.$emit('update:params', this.params)
    },
    monthFormat (dtString) {
      return DateTime.fromISO(dtString).toFormat('LLL yyyy')
    },
    dateFormat (dtString) {
      // return DateTime.fromISO(dtString).toISODate()
      return DateTime.fromISO(dtString).toLocaleString(DateTime.DATE_MED)
    },
    format (dtString) {
      if (this.pickerType === 'month') {
        return this.monthFormat(dtString)
      } else {
        return this.dateFormat(dtString)
      }
    }
  },
  watch: {
    // dateRange () {
    //   this.dateRange = this.dateRange.sort()
    // }
  },
  computed: {
    ...mapGetters('identity', ['spaces', 'getSpaceById', 'getPractitionerById']),
    facetStatuses () {
      return this.getObjectPath(this.facets, 'status', [])
    },
    facetPractitioners () {
      let res = []
      if (this.facets && this.facets.practitioner_id) {
        let facetIds = Object.keys(this.facets.practitioner_id)
        facetIds.forEach((id) => {
          let space = this.getPractitionerById(id)
          if (space) {
            space.count = this.facets.practitioner_id[id]
            res.push(space)
          }
        })
      }
      return res
    },
    facetSpaces () {
      let res = []
      if (this.facets && this.facets.space_id) {
        let facetIds = Object.keys(this.facets.space_id)
        facetIds.forEach((id) => {
          let space = this.getSpaceById(id)
          if (space) {
            space.count = this.facets.space_id[id]
            res.push(space)
          }
        })
      }
      return res
    },
    dateRangeString () {
      if (this.dateRange) {
        let rng = JSON.parse(JSON.stringify(this.dateRange)).sort()
        if (this.dateRange.length === 2) {
          if (this.pickerType === 'month' && rng[0] === rng[1]) {
            return this.monthFormat(rng[0])

          }
          return this.format(rng[0]) + ' - ' + this.format(rng[1])
        }
        if (this.dateRange.length === 1) {
          return `After ${this.format(rng[0])} - (Please choose a second date)`
        }
      }
      return 'Pick a start date'
    },
    params () {
      let params = {
        facets: this.facetString,
        page_size: this.pageSize,
        ordering: '-date'
      }
      if (this.dateRange.length > 0) {
        if (this.pickerType === 'month') {
          params['after_utc'] = this.dateRange[0] + '-01'
        } else {
          params['after_utc'] = this.dateRange[0]
        }
      }
      if (this.dateRange.length === 2) {
        if (this.pickerType === 'month') {
          let before = DateTime.fromISO(this.dateRange[1]).endOf('month').toISODate()
          params['before_utc'] = before
        } else {
          params['before_utc'] = this.dateRange[1]
        }
      }
      if (this.selectedSpaces && this.selectedSpaces.length > 0) {
        params['space_id-in'] = this.selectedSpaces.join(',')
      }
      if (this.selectedPractitioners && this.selectedPractitioners.length > 0) {
        params['practitioner_id-in'] = this.selectedPractitioners.join(',')
      }
      if (this.selectedStatuses && this.selectedStatuses.length > 0) {
        params['status-in'] = this.selectedStatuses.join(',')
      }
      return params
    },
    spaces () {
      return this.spacesList.filter((space) => { return space.role === 'Owner' })
    }
  }
}
</script>
