<template>
<div>
  <v-btn small text
    :disabled='disabled'
    @click='downloadDialog=true' >
    <v-icon small class='mr-2'>mdi-cloud-download</v-icon>
    Download
  </v-btn>

  <v-dialog v-model='downloadDialog' width='460' >
    <v-card :loading='downloading' :disabled='downloading' >
      <v-card-title class='subtitle-1 overline py-0 grey lighten-4' >
        Download
        <v-spacer />
        <v-btn @click='downloadDialog=false' icon >
          <v-icon small >mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text class='text-center' >
        <template v-if='downloadProgress > 0' >
          <v-progress-circular
            :value='downloadProgress'
            :color="progressColor"
            class='mt-4' width="2" size="60" >
            {{downloadProgress}}%
          </v-progress-circular>
          <p>{{downloadMessage}}</p>
        </template>
        <v-text-field outlined persistent-hint
          label='file name' class='mt-3'
          hint='the filename to save this file as'
          suffix='.csv'
          v-model='filename' />

        <template v-if='formats && formats.length > 0' >
          <v-subheader class='overline pa-0 ma-0 justify-center' >Choose a download format</v-subheader>
          <v-btn-toggle
            v-model="serializer" color='primary'
            mandatory dense >
            <v-btn v-for='format in formats' :key='format.value' :value='format.value'>
              {{format.title}}
            </v-btn>
          </v-btn-toggle>
        </template>
      </v-card-text>
      <v-divider />
      <v-card-actions >
        <strong v-if="recordCount && recordCount > 0" >{{recordCount}} records</strong>
        <v-spacer />
        <v-btn @click='startDownload' color='primary' :loading='downloading' >Start download</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>
<script>
import DownloadMixin from '@/mixins/download.js'

export default {
  name: 'Downloader',
  mixins: [DownloadMixin],
  props: {
    request: { type: Object, required: true },
    pages: { type: Number, required: true },
    initialFilename: { type: String, default: 'download.csv' },
    formats: { type: Array },
    disabled: { type: Boolean, default: false },
    parserFunction: { type: Function, required: false}
  },
  data () {
    return {
      filename: null,
      serializer: null,
      downloadDialog: false,
      downloadMessage: '',
      downloading: false,
      downloadProgress: 0
    }
  },
  mounted () {
    if (this.initialFilename) {
      this.filename = this.initialFilename
    } else {
      this.filename = 'download'
    }
  },
  methods: {
    reset () {
      this.serializer = null
      // this.downloadDialog = false
      this.downloadMessage = ''
      this.downloading = false
      this.downloadProgress = 0
    },
    startDownload () {
      if (this.serializer) {
        this.request.params['serializer'] = this.serializer
      }
      if (!this.filename) { this.filename = 'download.csv' }
      if (!this.filename.toLowerCase().endsWith('.csv')) {
        this.filename = this.filename + '.csv'
      }
      this.toCSV(this.filename)
    }
  },
  computed: {
    recordCount () {
      return this.getObjectPath(this.request, 'result.count')
    },
    progressColor () {
      if (this.downloadProgress === 100) { return 'green' }
      return 'amber'
    }
  },
  watch: {
    downloadDialog () {
      if (this.downloadDialog === true) { this.reset() }
    }
  }
}
</script>
