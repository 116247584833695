<template>
<query-page
  title='Invoices'
  icon='mdi-file-document-outline'
  :request='req'
  :page.sync='page'
  :pages='pages'
  @refresh='get' >

  <downloader
    :request='req'
    :pages='pages'
    :disabled='(count===0)'
    :formats='formats'
    initial-filename='invoices'
    slot='toolbar-actions' />

  <invoice-filter
    @refresh='get'
    :params.sync='params'
    :facets='facets'
    :facet-string='facetString'
    :loading='req.loading'
    slot='filters' />

  <template slot='data' >
    <!-- <pre v-text='params' /> -->
    <v-card tile flat >
      <v-system-bar flat dark class='caption' >
        <v-spacer />
        Showing {{invoices.length}} of {{count}} invoices
      </v-system-bar>
      <v-list class='pa-0 elevation-0' dense >
        <v-overlay absolute :value='req.loading' opacity="0.1" >
          <v-progress-circular size='70' indeterminate color='amber' />
          <p>Loading</p>
        </v-overlay>
        <template v-for='invoice in invoices' >
          <invoice-list-item :item='invoice' :key='invoice.id' />
          <v-divider :key='`div-${invoice.id}`' />
        </template>
      </v-list>
    </v-card>
    <v-card flat v-if='!req.loading && count === 0' >
      <v-card-text >
        No invoices matched your search. Perhaps broaden your filters?
      </v-card-text>
    </v-card>
    <!-- <pre v-text='relationships' /> -->
  </template>

</query-page>
</template>
<script>
import QueryPage from '@/components/layouts/QueryPage.vue'
import InvoiceFilter from '@/components/filters/InvoiceFilter.vue'
import InvoiceListItem from '@/components/listitems/InvoiceListItem.vue'
import Downloader from '@/components/ui/Downloader.vue'
import {DateTime} from 'luxon'

export default {
  name: 'InvoicesPage',
  components: {QueryPage, InvoiceFilter, InvoiceListItem, Downloader},
  data () {
    return {
      // facetsFields: 'spaces,practitioner',
      req: {
        resource: 'invoiceguru.invoices',
        ordering: '-date'
      },
      formats: [
        { title: 'All details', value: null },
        { title: 'Xero Compatible', value: 'xero' }
      ],
      page: 1,
      pagesize: 20,
      facetString: 'practitioner_id,space_id,status',
      params: {
        facets: 'practitioner_id,space_id,status'
      }
    }
  },
  mounted () {
    let start = DateTime.local().startOf('month').toISODate()
    let end = DateTime.local().endOf('month').toISODate()
    this.params = {
      facets: this.facetString,
      page_size: this.pagesize,
      after_utc: start,
      before_utc: end
    }
    this.get()
  },
  watch: {
    params () { this.get() },
    page () {
      this.params.page = this.page
      this.get()
    }
  },
  computed: {
    invoices () {
      return this.getObjectPath(this.req, 'result.results', [])
    },
    facets () {
      return this.getObjectPath(this.req, 'result.facets', {})
    },
    pages () {
      return this.getObjectPath(this.req, 'result.pages', 1)
      // if (this.count > 0) {
      //   return Math.ceil(parseInt(this.count) / this.pagesize)
      // }
      // return 1
    },
    count () {
      return this.getObjectPath(this.req, 'result.count', 0)
    }
  },
  methods: {
    get () {
      // this.req.params = {
      //   facets: this.facetsFields
      // }
      this.req.params = this.params
      this.$request('get', this.req)
    }
  }
}
</script>
