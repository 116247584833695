import Papa from 'papaparse/papaparse'

export default {
  methods: {
    download (filename, csvString) {
      var blob = new Blob([csvString])
      // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
      if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, filename);
      } else {
          var a = window.document.createElement('a');
          a.href = window.URL.createObjectURL(blob, {type: 'text/csv'})
          a.download = filename;
          document.body.appendChild(a)
          // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
          a.click();
          document.body.removeChild(a);
      }
    },
    async toCSV (filename) {
      let pages = []
      this.downloadMessage = 'Fetching data'
      this.downloading = true
      for (let x=0; x<this.pages; x++) {
        let config = JSON.parse(JSON.stringify(this.request))
        config.params.page = (x + 1)
        // this.configureRequest(config)
        let res = await this.$request('get', config)
        this.downloadProgress = 1
        if (res.status === 200) {
          let data = res.data.results
          if (this.parserFunction) {
            data = this.parserFunction(data)
          }
          pages = pages.concat(data)
          console.log(x)
          if (x > 0) {
            let percent = Math.round(x / parseInt(this.pages) * 100)
            console.log(percent)
            this.downloadProgress = percent
          }
        }
      }
      this.downloadMessage = 'Generating CSV file'
      let csv = Papa.unparse(pages)
      this.download(filename, csv)
      this.downloadMessage = 'Done!'
      this.downloadProgress = 100
      this.downloading = false
      return csv
    }
  }
}
