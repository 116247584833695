<template>
<v-container fluid>
  <request-status v-if='request' :request='request' :message200='false' />
  <!-- a slot to use to show a navigation drawer -->
  <slot name='peek' ></slot>
  <v-row>
    <v-col cols=12>
    <v-toolbar dense flat full-width >
      <template v-if='icon' >
        <v-progress-circular
          v-if='request.loading'
          indeterminate size=24 width=2 color='amber' />
        <v-btn v-else @click='$emit("refresh")' icon >
          <v-icon >{{icon}}</v-icon>
        </v-btn>

        <v-divider vertical class='mx-3' />
      </template>
      <v-toolbar-title v-text='title' />
      <v-spacer />
      <slot name='toolbar-actions' ></slot>
      <v-select label='Quick views'
        v-if='views && views.length > 0'
        :items='views' class='mt-3' />
      <v-btn icon
        @click='$emit("update:page", page - 1)'
        :disabled='page <= 1' >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <span class='caption' >
      {{page}} of {{pages}}
      </span>
      <v-btn icon
        @click='$emit("update:page", page + 1)'
        :disabled='page === pages' >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-toolbar>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols='4'>
      <slot name='filters' ></slot>
    </v-col>
    <v-col :cols='dataCols'>
      <v-card height='100%' tile >
      <slot name='data' ></slot>
      </v-card>
    </v-col>
    <v-col cols='3'>
      <v-card height='100%' >
      <slot name='actions' ></slot>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>
<script>
export default {
  name: 'QueryPage',
  props: {
    title: { type: String },
    icon: { type: String },
    views: { type: Array, default: () => { return [] } },
    request: { type: Object, required: false },
    page: { default: 1 },
    pages: { default: 1 }
  },
  data () {
    return {
      selected: []
    }
  },
  computed: {
    dataCols () {
      if (this.selected && this.selected.length > 0) {
        return 6
      } else {
        return 8
      }
    }
  }
}
</script>
