<style scoped>
.price { min-width: 80px; }
</style>
<template>
  <v-list-item >
    <v-list-item-action style='width:15px;' >
      <v-btn icon
        @click='download'
        :loading='downloading' ><v-icon>mdi-cloud-download</v-icon></v-btn>
    </v-list-item-action>
    <v-list-item-action v-if='canShare' >
      <v-btn @click='share' icon ><v-icon>mdi-export-variant</v-icon></v-btn>
    </v-list-item-action>
    <v-divider vertical class='mr-1' />
    <v-list-item-content>
      <v-list-item-title>
        #{{invoice.id}}.
        {{invoice.title}}
        <v-btn x-small icon
          :href='viewUrl' target="_blank"
          class='ml-1' >
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
        <v-chip x-small outlined label
          :color='statuscolor'
          class='float-right mr-2' >{{invoice.status}}</v-chip>
      </v-list-item-title>
      <v-list-item-subtitle >
        <span class='mr-2' ><strong>Date:</strong> {{item.date}}</span>
        <span><strong>Due:</strong> {{item.due_date}}</span>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action class='mr-2 price' >
      {{ formatCurrency(invoice.currency, invoice.invoice_amount) }}
      <v-list-item-action-text>Total</v-list-item-action-text>
    </v-list-item-action>
    <v-list-item-action class='mr-2 price' >
      {{ formatCurrency(invoice.currency, invoice.amount_paid) }}
      <v-list-item-action-text>Payments</v-list-item-action-text>
    </v-list-item-action>
    <v-divider vertical />
    <v-list-item-action class='price' >
      <strong>{{ formatCurrency(invoice.currency, invoice.total_due) }}</strong>
      <v-list-item-action-text>Due</v-list-item-action-text>
    </v-list-item-action>
  </v-list-item>
</template>
<script>
export default {
  name: 'InvoiceListItem',
  props: {
    item: { type: Object }
  },
  data () {
    return {
      req: { resource: 'invoiceguru.invoices' },
      downloading: false
    }
  },
  computed: {
    invoice () { return this.item },
    statuscolor () {
      if (this.invoice.status === 'paid') { return 'success' }
      if (this.invoice.status === 'new') { return 'info' }
      if (this.invoice.status === 'sent') { return 'primary' }
      return 'grey'
    },
    viewUrl () {
      const baseUrl = process.env.VUE_APP_INVOICEGURU_API_ROOT
      let path = this.invoice.get_download_url
      return baseUrl + path
    },
    canShare () {
      return (window.navigator && window.navigator.share)
    }
  },
  methods: {
    async getDownloadUrl () {
      if (this.invoice && this.invoice.id) {
        this.req.resourceId = this.invoice.id
        this.req.extra = 'files'
        let res = await this.$request('get', this.req)
        let url = this.getObjectPath(res, 'data.download')
        return url
      }
    },
    async download () {
      this.downloading = true
      let url = await this.getDownloadUrl()
      if (url) {
        let filename = 'inv-' + this.invoice.id + '.pdf'
        fetch(url)
          .then(res => res.blob()) // Gets the response and returns it as a blob
          .then(blob => {
            var a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(blob, {type: 'text/pdf'})
            a.download = filename;
            document.body.appendChild(a)
            a.click();
            document.body.removeChild(a)
            this.downloading = false
          })
      } else {
        this.downloading = false
      }
    },
    async share () {
      let url = this.getDownloadUrl()
      if (url) {
        if (url) {
          const response = await fetch(url);
          const buffer = await response.arrayBuffer();
          const pdf = new File([buffer], "invoice.pdf", { type: "application/pdf" });
          const files = [pdf];
          const baseUrl = process.env.VUE_APP_INVOICEGURU_API_ROOT
          navigator.share({
            files,
            title: this.invoice.title,
            text: baseUrl + this.invoice.get_download_url
          })
        }
      }
    }
  }
}
</script>
